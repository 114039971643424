import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import slugify from 'slugify';

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"
import Meats from "../components/meats"
import Services from "../components/services"

const Term = ({ pageContext }) => {
  console.log(pageContext)

  const renderServices = (services) => {
    return services.map((service, i) => {
      const logo = service.data.Logo.localFiles[0].childrenImageSharp[0].gatsbyImageData;
      return(
        <div key={i} className='border-zinc-900 rounded border-2 my-4 p-4 max-w-2xl mx-auto relative flex flex-wrap'>
          <div className='p-1 h-16 w-16 flex items-center justify-center'>
            {service.data.Logo && (
                      <GatsbyImage
                        image={logo}
                        alt={service.data.Name}
                      />
            )}

          </div>
          <a className={'text-xl font-bold underline ml-4 mt-4'} href={`/${slugify(service.data.Name, {lower: true})}`}>{service.data.Name}</a>
          { service.data.Subscriptions && (
            <span className={'bg-zinc-900 text-yellow-300 py-1 px-2 absolute top-1 right-1 md:top-4 md:right-4 block text-xs font-medium flex items-center rounded'}>
              <svg className='h-4 w-4 mr-1 fill-yellow-300' xmlns="http://www.w3.org/2000/svg" viewBox="-1.5 -2.5 24 24" width="24" fill="currentColor"><path d="M17.83 4.194l.42-1.377a1 1 0 1 1 1.913.585l-1.17 3.825a1 1 0 0 1-1.248.664l-3.825-1.17a1 1 0 1 1 .585-1.912l1.672.511A7.381 7.381 0 0 0 3.185 6.584l-.26.633a1 1 0 1 1-1.85-.758l.26-.633A9.381 9.381 0 0 1 17.83 4.194zM2.308 14.807l-.327 1.311a1 1 0 1 1-1.94-.484l.967-3.88a1 1 0 0 1 1.265-.716l3.828.954a1 1 0 0 1-.484 1.941l-1.786-.445a7.384 7.384 0 0 0 13.216-1.792 1 1 0 1 1 1.906.608 9.381 9.381 0 0 1-5.38 5.831 9.386 9.386 0 0 1-11.265-3.328z"></path></svg>
              Subscriptions
            </span>
          )}
          <div className='basis-full'>
            <Meats service={service}/>
          </div>
        </div>
      )
    })
  }

  const renderStats = (matchingServices) => {
    return (
      <div>
        <p>{matchingServices.length} Services</p>
      </div>
    )
  }

  const matchingServices = pageContext.services.filter(service => { 
    const products = service.data.Products;
      if (products) {
        const prod = products.map(p => p.toLowerCase())
        const included = prod.includes(pageContext.product)
        return included;
      } else {
        return false;
      }
    })



  return (
    <>
    <Layout>
      <div className=''>
        <Hero header={pageContext.term}/>
        <div className='mx-2'>
          <Services services={matchingServices}/>
        </div>
      </div>
    </Layout>
    </>
  )
}

export const Head = ({pageContext}) => (
    <SEO title={`Meatbox | ${pageContext.term.toUpperCase()}`} description={`Find meat and fish delivery services online.`} url={`https://meatbox.fyi/${pageContext.slug}`}/>
)

export default Term
